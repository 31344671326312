@font-face {
  font-family: alphakind;
  src: url('../public/alphakind.otf');
  font-weight: bold;
}

.App {
  text-align: center;
  background-color: #D54B3B;
  min-height: 100vh;
  display: flex;
  font-family: alphakind;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1.5rem + 2vmin);
  color: white;
}

.App-logo {
  height: calc(1.5rem + 40vmin);
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}
